// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CategoryOne_container__azdel {\n  padding: 0rem 5rem;\n  box-sizing: border-box;\n}\n.CategoryOne_container__azdel .CategoryOne_wrapper__-7-Uv .CategoryOne_heading__aCRlU {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.CategoryOne_container__azdel .CategoryOne_wrapper__-7-Uv .CategoryOne_heading__aCRlU label {\n  line-height: 56px;\n  color: #000000;\n  font-size: 35px;\n  font-weight: 500;\n}\n.CategoryOne_container__azdel .CategoryOne_wrapper__-7-Uv .CategoryOne_heading__aCRlU p {\n  font-size: 18px;\n  line-height: 56px;\n  text-decoration-line: underline;\n  color: #000000;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/Components/ExploreComponents/CategoryOne/CategoryOne.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,sBAAA;AACJ;AAEQ;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;AAAZ;AAEY;EACI,iBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AAAhB;AAGY;EAEI,eAAA;EACA,iBAAA;EACA,+BAAA;EACA,cAAA;EACA,eAAA;AAFhB","sourcesContent":[".container {\n    padding: 0rem 5rem;\n    box-sizing: border-box;\n\n    .wrapper {\n        .heading {\n            display: flex;\n            align-items: center;\n            justify-content: space-between;\n\n            label {\n                line-height: 56px;\n                color: #000000;\n                font-size: 35px;\n                font-weight: 500;\n            }\n\n            p {\n\n                font-size: 18px;\n                line-height: 56px;\n                text-decoration-line: underline;\n                color: #000000;\n                cursor: pointer;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CategoryOne_container__azdel",
	"wrapper": "CategoryOne_wrapper__-7-Uv",
	"heading": "CategoryOne_heading__aCRlU"
};
export default ___CSS_LOADER_EXPORT___;
