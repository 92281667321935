// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/backgrounds/background.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home_container__sphJX {\n  width: 100%;\n  padding: 0 5rem;\n  box-sizing: border-box;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: 100% 254%;\n  background-size: 45% 90%;\n  padding-bottom: 18rem;\n}\n@media screen and (max-width: 600px) {\n  .Home_container__sphJX {\n    padding: 0 2rem;\n    background-position: 99% 357%;\n    background-size: 61% 89%;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/Home/Home.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,eAAA;EACA,sBAAA;EACA,yDAAA;EACA,4BAAA;EACA,8BAAA;EACA,wBAAA;EACA,qBAAA;AACJ;AACI;EAVJ;IAWQ,eAAA;IACA,6BAAA;IACA,wBAAA;EAEN;AACF","sourcesContent":[".container {\r\n    width: 100%;\r\n    padding: 0 5rem;\r\n    box-sizing: border-box;\r\n    background-image: url(\"../../Assets//backgrounds//background.svg\");\r\n    background-repeat: no-repeat;\r\n    background-position: 100% 254%;\r\n    background-size: 45% 90%;\r\n    padding-bottom: 18rem;\r\n\r\n    @media screen and (max-width:600px) {\r\n        padding: 0 2rem;\r\n        background-position: 99% 357%;\r\n        background-size: 61% 89%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Home_container__sphJX"
};
export default ___CSS_LOADER_EXPORT___;
