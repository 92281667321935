// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filter_container__KXQgC {\n  width: 100%;\n  padding: 0.4rem 5rem;\n  box-sizing: border-box;\n}\n.filter_container__KXQgC .filter_wrapper__9t9HG {\n  width: 100%;\n}\n.filter_container__KXQgC .filter_wrapper__9t9HG .filter_inputWrapper__zKh8U {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n}\n.filter_container__KXQgC .filter_wrapper__9t9HG .filter_inputWrapper__zKh8U .filter_leftWrapper__f6MXy {\n  display: flex;\n  width: 30%;\n  column-gap: 2rem;\n  justify-content: space-between;\n}\n.filter_container__KXQgC .filter_wrapper__9t9HG .filter_inputWrapper__zKh8U .filter_rightWrapper__McLId {\n  width: 20%;\n}", "",{"version":3,"sources":["webpack://./src/Components/ExploreComponents/filter/filter.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,oBAAA;EACA,sBAAA;AACJ;AACI;EACI,WAAA;AACR;AAEQ;EACI,aAAA;EACA,WAAA;EACA,8BAAA;AAAZ;AAEY;EACI,aAAA;EACA,UAAA;EACA,gBAAA;EACA,8BAAA;AAAhB;AAGY;EACI,UAAA;AADhB","sourcesContent":[".container {\r\n    width: 100%;\r\n    padding: 0.4rem 5rem;\r\n    box-sizing: border-box;\r\n\r\n    .wrapper {\r\n        width: 100%;\r\n\r\n\r\n        .inputWrapper {\r\n            display: flex;\r\n            width: 100%;\r\n            justify-content: space-between;\r\n\r\n            .leftWrapper {\r\n                display: flex;\r\n                width: 30%;\r\n                column-gap: 2rem;\r\n                justify-content: space-between;\r\n            }\r\n\r\n            .rightWrapper {\r\n                width: 20%;\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "filter_container__KXQgC",
	"wrapper": "filter_wrapper__9t9HG",
	"inputWrapper": "filter_inputWrapper__zKh8U",
	"leftWrapper": "filter_leftWrapper__f6MXy",
	"rightWrapper": "filter_rightWrapper__McLId"
};
export default ___CSS_LOADER_EXPORT___;
