// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".owned_container__dPPSB {\n  width: 100%;\n}\n.owned_container__dPPSB .owned_wrapper__tCZN\\+ {\n  width: 100%;\n}\n.owned_container__dPPSB .owned_wrapper__tCZN\\+ .owned_cardWrapper__IkrDc {\n  display: flex;\n  row-gap: 1rem;\n  column-gap: 1rem;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/Components/ProfileComponent/Tabs/Owned/owned.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AACI;EACI,WAAA;AACR;AACQ;EACI,aAAA;EACA,aAAA;EACA,gBAAA;EACA,eAAA;EACA,8BAAA;EACA,mBAAA;AACZ","sourcesContent":[".container {\n    width: 100%;\n\n    .wrapper {\n        width: 100%;\n\n        .cardWrapper {\n            display: flex;\n            row-gap: 1rem;\n            column-gap: 1rem;\n            flex-wrap: wrap;\n            justify-content: space-between;\n            align-items: center;\n        }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "owned_container__dPPSB",
	"wrapper": "owned_wrapper__tCZN+",
	"cardWrapper": "owned_cardWrapper__IkrDc"
};
export default ___CSS_LOADER_EXPORT___;
